// Component Input
// ========================================================================

.form-control[readonly] {
  opacity: 1;
}

select.form-control[multiple] {
  background-image: none !important;
}

.input-group {
  .form-control.is-invalid ~ .input-group-append .input-group-text {
    border-color: $danger;
  }
}
